import { PropsWithChildren } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const RootProvider = ({children}:PropsWithChildren):JSX.Element => {
  return (
    <GoogleOAuthProvider
        clientId='' >
        {children}
    </GoogleOAuthProvider>
  )
}