import { Footer } from "../../components";

export const PrivacyPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-[100dvh]">
      <main className="flex-1">
        <section className="w-full pt-12 md:pt-24 lg:pt-32 border-y">
          <div className="px-4 md:px-6 space-y-10 xl:space-y-16">
            <div className="max-w-[1300px] mx-auto px-4 sm:px-6 md:px-10">
              <div className="space-y-6">
                <h1 className="lg:leading-tighter text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
                  Privacy Policy
                </h1>
                <p className="text-gray-500 md:text-xl dark:text-gray-400 mt-6">
                  Effective Date: January 1, 2024
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">1. Information We Collect</h2>
                <h3 className="text-xl font-semibold">Personal Information</h3>
                <p className="text-gray-500 dark:text-gray-400">
                  We collect personal information from you when you register for our services, upon approving the specific privacy terms for each app. This information may include:
                </p>
                <ul className="list-disc list-inside text-gray-500 dark:text-gray-400">
                  <li>Name</li>
                  <li>Email address</li>
                  <li>Other information relevant to the services you are registering for</li>
                </ul>
                <h3 className="text-xl font-semibold">Anonymous Analytics</h3>
                <p className="text-gray-500 dark:text-gray-400">
                  We also collect anonymous analytics on page views and usage of our services to help us improve our website and services. This data is collected using cookies and other tracking technologies and, where possible, is fully anonymized.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">2. How We Use Your Information</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  We use the information we collect from you for the following purposes:
                </p>
                <ul className="list-disc list-inside text-gray-500 dark:text-gray-400">
                  <li>To provide and manage the services you request</li>
                  <li>To improve our website and services</li>
                  <li>To understand how users interact with our services</li>
                </ul>
                <p className="text-gray-500 dark:text-gray-400">
                  We will never share your personal information with third parties. All collected information is stored with a high level of security.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">3. Cookies and Tracking Technologies</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  We use cookies and similar tracking technologies to collect data on usage patterns and to improve our site. The data collected is generally anonymous. You can control the use of cookies at the individual browser level.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">4. User Rights</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  You have the right to:
                </p>
                <ul className="list-disc list-inside text-gray-500 dark:text-gray-400">
                  <li>Access the personal information we hold about you</li>
                  <li>Request the correction of any inaccurate information</li>
                  <li>Request the deletion of your data, subject to legal requirements</li>
                </ul>
                <p className="text-gray-500 dark:text-gray-400">
                  To exercise these rights, please contact us at <a href="mailto:support@thinkdifferentlabs.com" className="text-blue-500 hover:underline">support@thinkdifferentlabs.com</a>.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">5. Data Security</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  We implement high security standards and use third-party tools to ensure a high level of protection, anonymity, and data encryption for your personal information.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">6. Third-Party Links</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We encourage you to read the privacy policies of any website you visit.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">7. Children's Privacy</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will delete that information.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">8. Changes to This Privacy Policy</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  We may update this Privacy Policy from time to time. When we do, we will revise the "Effective Date" at the top of this policy. We will notify registered users of any significant changes via email or through our site.
                </p>
              </div>
              <div className="mt-10 space-y-6 mb-16">
                <h2 className="text-2xl font-bold">9. Contact Us</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  If you have any questions about this Privacy Policy, please contact us at:
                </p>
                <address className="text-gray-500 dark:text-gray-400 not-italic">
                  Think Different Labs LLC<br />
                  Email: <a href="mailto:support@thinkdifferentlabs.com" className="text-blue-500 hover:underline">support@thinkdifferentlabs.com</a>
                </address>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};