// list of flags: https://flagicons.lipis.dev/

import "/node_modules/flag-icons/css/flag-icons.min.css";

interface CountryFlagProps {
  countryName: string;
  countryCode: string;
  isDisabled: boolean;
  withBorder?: boolean; // Optional border flag
}

const CountryFlag = ({ countryName, countryCode, isDisabled, withBorder }: CountryFlagProps): JSX.Element => {
  const flagClasses = `fi fi-${countryCode} text-6xl ${isDisabled ? 'grayscale' : ''} ${withBorder ? 'border border-gray-200' : ''}`;
  return (
    <div className="flex flex-col items-center">
      <span className={flagClasses}></span>
      <span className={`mt-2 ${isDisabled ? 'text-gray-400' : 'text-black'}`}>{countryName}</span>
    </div>
  );
};

export const CountriesSection = (): JSX.Element => {
  return (
    <div className="container flex flex-col items-center gap-8 mx-auto my-32" id="where">
      <p className="text-base font-medium leading-7 text-center text-dark-grey-600">Supported in the following countries</p>
      <div className="flex flex-wrap items-center justify-center w-full gap-6 lg:gap-0 lg:flex-nowrap lg:justify-between">
        <CountryFlag countryName="Hong Kong" countryCode="hk" isDisabled={true} />
        <CountryFlag countryName="Philippines" countryCode="ph" isDisabled={true} />
        <CountryFlag countryName="Thailand" countryCode="th" isDisabled={false} />
        <CountryFlag countryName="Singapore" countryCode="sg" isDisabled={true} withBorder={true} /> {/* Singapore flag will have a border */}
        <CountryFlag countryName="Vietnam" countryCode="vn" isDisabled={true} />
      </div>
    </div>
  );
};
