import { Button } from 'monday-ui-react-core';
import SyncPeople from '../../../assets/SyncPeople.png';

export const GoogleSyncOAuthSuccessPage = (): JSX.Element => {
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>Authorization Sucess</h1>
      <p>
        You can now close this window, and start enjoying your Google-Sync
      </p>
      <img src={SyncPeople} alt="Sucess" style={{ width: '200px', marginTop: '20px' }} />
      <div style={{ marginTop: '20px' }}>
        <Button>Close Tab</Button>
      </div>
    </div>
  );
};
