import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Loader } from 'monday-ui-react-core';
import SyncPeople from '../../../assets/SyncPeople.png';

interface QueryParams {
  userId?: string;
  error?: string;
  state?: string;
}

const parseQueryParams = (search: string): QueryParams => {
  const params = new URLSearchParams(search);
  return {
    state: params.get('state') || undefined,
    userId: params.get('userId') || undefined,
    error: params.get('error') || undefined,
  };
};

export const GoogleSyncOAuthErrorPage = (): JSX.Element => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const { state, userId, error } = parseQueryParams(location.search);

  useEffect(() => {
    setLoading(false);
  }, [state, userId, error]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Loader size={Loader.sizes.LARGE} />
      </div>
    );
  }

  return (
    <div className="mt-20" style={{ textAlign: 'center', padding: '20px' }}>
      <h1 className="lg:leading-tighter text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
        Can't gather proper permissions for contacts sync :/
      </h1>
      <div className="flex flex-col lg:flex-row justify-center items-center mt-20">
        <img src={SyncPeople} alt="Sync" className="w-1/5 mb-5 lg:mb-0 lg:mr-5" />
        <p className="flex-1 max-w-full lg:max-w-1/2">
          It seems you have declined the permissions we requested. We need these permissions to compare your Monday contacts and to add new ones.
          If you would like to understand why we need these permissions, please contact us.
        </p>
      </div>
      <div className="mt-5">
        <Button onClick={() => window.location.href = 'mailto:support@example.com'}>Contact Us</Button>
        <Button onClick={() => window.location.href = 'http://localhost:3000/'}>Try Again</Button>
      </div>
    </div>
  )
};
