import { FaGoogle, FaSync } from 'react-icons/fa';
import { CgMonday } from "react-icons/cg";

export const GoogleSyncPage = () => {
  const handleCTAClick = () => {
    window.open('https://monday.com/marketplace', '_blank');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100">
      <div className="container mx-auto px-4 py-12">
        <header className="text-center mb-12">
          <h1 className="text-4xl font-bold text-indigo-800 mb-4">Google Sync for Monday.com</h1>
          <p className="text-xl text-gray-600">Seamlessly sync your Google Contacts with your Monday.com boards</p>
        </header>

        <div className="grid md:grid-cols-2 gap-12 items-center mb-16">
          <div>
            <h2 className="text-2xl font-semibold text-indigo-700 mb-4">Stay Connected and Organized</h2>
            <p className="text-gray-700 mb-6">
              Our Google Sync app for Monday.com allows you to effortlessly synchronize your Google Contacts with your Monday.com boards. Keep your customer information up-to-date and accessible, streamlining your workflow and boosting team efficiency.
            </p>
            <ul className="space-y-2 text-gray-700 mb-8">
              <li className="flex items-center"><FaSync className="text-green-500 mr-2" /> Automatic contact updates</li>
              <li className="flex items-center"><CgMonday className="text-blue-500 mr-2" /> Seamless Monday.com integration</li>
              <li className="flex items-center"><FaGoogle className="text-red-500 mr-2" /> Direct access to Google Contacts</li>
            </ul>
            <button 
              onClick={handleCTAClick}
              className="bg-indigo-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-indigo-700 transition duration-300"
            >
              Learn More and Install
            </button>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-xl">
            <img 
              src="/placeholder-sync-illustration.svg" 
              alt="Google Sync Illustration" 
              className="w-full h-auto"
            />
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-8 mb-12">
          <h3 className="text-2xl font-semibold text-indigo-700 mb-4">How It Works</h3>
          <ol className="list-decimal list-inside space-y-4 text-gray-700">
            <li>Install the Google Sync app from the Monday.com marketplace</li>
            <li>Authorize access to your Google Contacts</li>
            <li>Select the Monday.com board you want to sync with</li>
            <li>Choose the contact fields you want to import</li>
            <li>Set up automatic sync intervals or manually sync anytime</li>
          </ol>
        </div>

        <div className="text-center">
          <h3 className="text-2xl font-semibold text-indigo-700 mb-4">Ready to Enhance Your Workflow?</h3>
          <p className="text-gray-700 mb-6">
            Start syncing your Google Contacts with Monday.com today and experience the power of seamless integration!
          </p>
          <button 
            onClick={handleCTAClick}
            className="bg-green-500 text-white font-semibold py-3 px-6 rounded-lg hover:bg-green-600 transition duration-300"
          >
            Get Started Now
          </button>
        </div>
      </div>
    </div>
  );
};