import { Footer } from "../../components";

export const TermsOfServicePage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-[100dvh]">
      <main className="flex-1">
        <section className="w-full pt-12 md:pt-24 lg:pt-32 border-y">
          <div className="px-4 md:px-6 space-y-10 xl:space-y-16">
            <div className="max-w-[1300px] mx-auto px-4 sm:px-6 md:px-10">
              <div className="space-y-6">
                <h1 className="lg:leading-tighter text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
                  Terms of Service
                </h1>
                <p className="text-gray-500 md:text-xl dark:text-gray-400 mt-6">
                  Effective Date: January 1, 2024
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">1. Acceptance of Terms</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  By accessing or using any part of our software projects, mobile apps, web applications, and other services (collectively, the "Services"), you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Services.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">2. Services Provided</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  Think Different Labs LLC provides various software solutions, including but not limited to mobile apps for recording audio, apps for tracking time and team work, web applications, and other projects. Each service may have specific terms, which will be presented to you upon usage or registration for that particular service.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">3. User Responsibilities</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  You agree to:
                </p>
                <ul className="list-disc list-inside text-gray-500 dark:text-gray-400">
                  <li>Use the Services only for lawful purposes and in accordance with these Terms.</li>
                  <li>Provide accurate, current, and complete information when creating an account or using the Services.</li>
                  <li>Maintain the security and confidentiality of your account and password.</li>
                </ul>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">4. Prohibited Activities</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  You agree not to:
                </p>
                <ul className="list-disc list-inside text-gray-500 dark:text-gray-400">
                  <li>Use the Services for any illegal or unauthorized purpose.</li>
                  <li>Attempt to gain unauthorized access to our systems or those of our partners.</li>
                  <li>Engage in any activity that disrupts or interferes with the Services.</li>
                </ul>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">5. Intellectual Property</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  All content, trademarks, service marks, logos, and other intellectual property displayed on the Services are the property of Think Different Labs LLC or their respective owners. You may not use, reproduce, or distribute any content from the Services without our prior written permission.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">6. Termination</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  We reserve the right to terminate or suspend your access to the Services, without prior notice or liability, for any reason, including but not limited to a breach of these Terms.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">7. Limitation of Liability</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  To the fullest extent permitted by law, Think Different Labs LLC shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use the Services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">8. Governing Law</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Think Different Labs LLC is established, without regard to its conflict of law principles.
                </p>
              </div>
              <div className="mt-10 space-y-6">
                <h2 className="text-2xl font-bold">9. Changes to Terms</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  We may update these Terms from time to time. When we do, we will revise the "Effective Date" at the top of these Terms. We will notify registered users of any significant changes via email or through our site.
                </p>
              </div>
              <div className="mt-10 space-y-6 mb-16">
                <h2 className="text-2xl font-bold">10. Contact Us</h2>
                <p className="text-gray-500 dark:text-gray-400">
                  If you have any questions about these Terms, please contact us at:
                </p>
                <address className="text-gray-500 dark:text-gray-400 not-italic">
                  Think Different Labs LLC<br />
                  Email: <a href="mailto:support@thinkdifferentlabs.com" className="text-blue-500 hover:underline">support@thinkdifferentlabs.com</a>
                </address>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};