import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import { MainPage, MondayIndex, TravelLight, PrivacyPage, TermsOfServicePage, 
  GoogleSyncRoutes } from './pages';
import ScrollToTop from './components/ScrollToTop';
import { TimeTrackerOAuthPage, TimeTrackerPage } from './pages/Monday/TimeTracker';
import { RootProvider } from './context/RootProvider';

function App() {
  return (
    <RootProvider>
      <Router>
        <ScrollToTop />
        <div className="flex flex-col min-h-[100dvh]">
          <Routes>
            <Route path="/" element={<MainPage/>} />
            <Route path="/privacy" element={<PrivacyPage/>} />
            <Route path="/terms" element={<TermsOfServicePage/>} />
            <Route path="/travel-light" element={<TravelLight/>} />
            <Route path="/monday" element={<MondayIndex/>} />
            <Route path="/monday/team-mobile-time-tracker" element={<TimeTrackerPage/>} />
            <Route path="/monday/team-mobile-time-tracker/oauth" element={<TimeTrackerOAuthPage/>} />
            <Route path="/monday/google-sync/*" element={<GoogleSyncRoutes/> }/>
          </Routes>
        </div>
      </Router>
    </RootProvider>
  );
}

export default App;
