// import { CardContent, Card } from "@/components/ui/card"
import { Link } from "react-router-dom";
import { Card, CardContent, ComingSoonCard, Footer } from "../../components";
import TLDHero from '../../assets/TDL-Hero.jpg';
import TravelLightHero from '../../assets/travelight.jpg';
import MondayComHero from '../../assets/MondayImage.png';

export const MainPage = ():JSX.Element => {
  return (
    <div className="flex flex-col min-h-[100dvh]">
      <main className="flex-1">
        <section className="w-full pt-12 md:pt-24 lg:pt-32 border-y">
          <div className="px-4 md:px-6 space-y-10 xl:space-y-16">
            <div className="grid max-w-[1300px] mx-auto gap-4 px-4 sm:px-6 md:px-10 md:grid-cols-2 md:gap-16">
              <div>
                <h1 className="lg:leading-tighter text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem]">
                  Empowering people and businesses with Innovative Solutions
                </h1>
              </div>
              <div className="flex flex-col items-start space-y-4">
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400 mt-6">
                  At Think Different Labs, we believe people come first, and we start with the customers we serve. 
                  We search for people and teams needs and research ideas to make them happier or/and more efficient.
                  Check what we already have, and feel free to point us to where else we can assist. 
                </p>
              </div>
            </div>
            <img
              alt="Hero"
              // className="mx-auto aspect-[3/1] overflow-hidden rounded-t-xl object-fit"
              className="mx-auto aspect-video overflow-hidden rounded-t-xl object-fit object-center"
              height="310"
              src={TLDHero}
              width="550"
            />
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter mb-8 sm:text-4xl">Our Projects</h2>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <Link className="group" to="/travel-light">
                <Card>
                  <img
                    alt="Travel Light"
                    className="aspect-video overflow-hidden rounded-t-xl object-cover object-center"
                    height="510"
                    src={TravelLightHero}
                    width="550"
                  />
                  <CardContent className="p-4">
                    <h3 className="text-lg font-bold group-hover:underline">Travel Light</h3>
                    <p className="text-gray-500 dark:text-gray-400">Pay with your phone while traveling. Stop using cash, travel light.</p>
                  </CardContent>
                </Card>
              </Link>
              <Link className="group" to="/monday">
                <Card>
                  <img
                    alt="Monday Apps"
                    className="aspect-video overflow-hidden rounded-t-xl object-cover object-center"
                    height="310"
                    src={MondayComHero}
                    width="550"
                  />
                  <CardContent className="p-4">
                    <h3 className="text-lg font-bold group-hover:underline">Monday apps</h3>
                    <p className="text-gray-500 dark:text-gray-400">A team using Monday.com? We got your back. Check our apps for teams and suggest more .</p>
                  </CardContent>
                </Card>
              </Link>
              <Link className="group" to="#">
                <ComingSoonCard />
              </Link>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}