import React from 'react';
import { Card, CardContent } from "./Card"
import ComingSoon from '../assets/comingsoon.jpg';

export const ComingSoonCard = ({ className = '' }:{ className?: string }):JSX.Element => {
  return (
    <Card className={`h-full flex flex-col ${className}`}>
      <img
        alt="Coming Soon"
        className="aspect-video overflow-hidden rounded-t-xl object-cover object-center"
        height="310"
        src={ComingSoon}
        width="550"
      />
      <CardContent className="p-4 flex-grow flex flex-col justify-between">
        <h3 className="text-lg font-bold group-hover:underline">More things are coming soon</h3>
        <p className="text-gray-500 dark:text-gray-400 mt-2">We keep open for ideas and problems to solve. Always looking for more.</p>
      </CardContent>
    </Card>
  )
}