import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Loader } from 'monday-ui-react-core';

interface QueryParams {
  token?: string;
  userId?: string;
  error?: string;
}

const parseQueryParams = (search: string): QueryParams => {
  const params = new URLSearchParams(search);
  return {
    token: params.get('token') || undefined,
    userId: params.get('userId') || undefined,
    error: params.get('error') || undefined,
  };
};

export const GoogleSyncOAuthPage = (): JSX.Element => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);

  const { token, userId, error: oauthError } = parseQueryParams(location.search);

  useEffect(() => {
    if (oauthError) {
      setError(oauthError);
      setLoading(false);
    } else if (token && userId) {
      fetch('/your-backend-endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, userId }),
      })
        .then(response => {
          if (response.ok) {
            setSuccess(true);
          } else {
            setError('Failed to process the token.');
          }
        })
        .catch(() => {
          setError('Failed to process the token.');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError('Invalid request parameters.');
      setLoading(false);
    }
  }, [token, userId, oauthError]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Loader size={Loader.sizes.LARGE} />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h1>Authorization Sucess</h1>
        <p>
          You can now close this window, and start enjoying your Google-Sync
        </p>
        <img src="path/to/error-image.jpg" alt="Error" style={{ width: '200px', marginTop: '20px' }} />
        <div style={{ marginTop: '20px' }}>
          <Button onClick={() => window.location.href = 'mailto:support@example.com'}>Close Tab</Button>
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h1>Authorization Successful</h1>
        <p>You have successfully authorized the application. You can now close this page.</p>
        <img src="path/to/success-image.jpg" alt="Success" style={{ width: '200px', marginTop: '20px' }} />
      </div>
    );
  }

  return <div></div>;
};
