import { Route, Routes } from 'react-router-dom';
import { GoogleSyncPage } from './GoogleSyncPage';
import { GoogleSyncOAuthPage } from './OAuth';
import { GoogleSyncOAuthErrorPage } from './OAuthError';
import { GoogleSyncOAuthSuccessPage } from './OAuthSuccess';

export const GoogleSyncRoutes = () => {
  return (
      <Routes>
        <Route path="/" element={GoogleSyncPage()} />
        <Route path="/oauth" element={GoogleSyncOAuthPage()} />
        <Route path="/oauth-error" element={GoogleSyncOAuthErrorPage()} />
        <Route path="/oauth-success" element={GoogleSyncOAuthSuccessPage()} />
      </Routes>
  );
};